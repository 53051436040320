<template>
  <div>
    <Error404 v-if="error404occured" />

    <main v-else id="content" role="main" class="">
      <!-- ========== Breadcrumb ========== -->
      <div class="container space-top-2 mt-md-6 pl-0">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-md-3 mb-0">
            <li class="breadcrumb-item">
              <!-- <a href="/events"> -->
              <router-link :to="{ path: '/events' }">
                Events
              </router-link>
              <!-- </a> -->
            </li>
            <li class="breadcrumb-item active" aria-current="page">{{ resource.title }}</li>
          </ol>
        </nav>
      </div>
      <!-- ========== End Breadcrumb ========== -->
      <!-- <div class="container">
        <div class="alert alert-soft-secondary" role="alert">
          This event has ended.
        </div>
      </div> -->

      <div class="position-relative">
        <!-- ========== Event Banner ========== -->
        <div class="container">
          <div class="row">
            <div class="col-lg-7 col-xl-8 pr-lg-5">
              <div class="position-relative">
                <div v-if="resource.status_description" class="alert alert-soft-secondary small mb-3 text-center">{{ resource.status_description }}</div>
                <img class="card-img-top shadow-sm" :src="require('@/assets/img/' + resource.image_url)" :alt="resource.title">
              </div>
            </div>
          </div>
        </div>
        <!-- ========== End Event Banner ========== -->

        <!-- ========== Sidebar Content Section ========== -->
        <div class="container position-lg-absolute top-0 right-0 left-0">
          <div class="row justify-content-end">
            <div id="stickyBlockStartPoint" class="col-lg-5 col-xl-4 position-relative z-index-2">
              <div
                class="js-sticky-block"
                data-hs-sticky-block-options="{
                         &quot;parentSelector&quot;: &quot;#stickyBlockStartPoint&quot;,
                         &quot;breakpoint&quot;: &quot;lg&quot;,
                         &quot;startPoint&quot;: &quot;#stickyBlockStartPoint&quot;,
                         &quot;endPoint&quot;: &quot;#stickyBlockEndPoint&quot;,
                         &quot;stickyOffsetTop&quot;: 20,
                         &quot;stickyOffsetBottom&quot;: 12
                       }"
              >
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center mb-3">
                      <span class="d-block small font-weight-bold text-cap">{{ resource.category }}</span>
                    <!-- <span v-if="resource.status" class="badge badge-soft-secondary">{{ resource.status }}</span> -->
                    </div>
                    <div class="mb-5">
                      <h4>{{ resource.title }}</h4>
                      <span v-if="resource.organiser">
                        <p class="small mb-0">by
                          <span v-for="(item, index) in resource.organiser" :key="item.company">{{ item.company }}<span v-if="index != Object.keys(resource.organiser).length - 1">, </span></span>
                        </p>
                      </span>
                    </div>

                    <!-- Icon Block -->
                    <div class="media text-body font-size-1 mb-2">
                      <div class="min-w-3rem text-center mr-3">
                        <i class="far fa-calendar-minus" />
                      </div>
                      <div class="media-body">
                        {{ resource.date }}
                      </div>
                    </div>
                    <!-- End Icon Block -->

                    <!-- Icon Block -->
                    <div v-if="resource.time" class="media text-body font-size-1 mb-2">
                      <div class="min-w-3rem text-center mr-3">
                        <i class="far fa-clock" />
                      </div>
                      <div class="media-body">
                        {{ resource.time }}
                      </div>
                    </div>
                    <!-- End Icon Block -->

                    <!-- Icon Block -->
                    <div class="media text-body font-size-1 mb-2">
                      <div class="min-w-3rem text-center mr-3">
                        <i class="fas fa-map-marker-alt" />
                      </div>
                      <div class="media-body">
                        {{ resource.location }}
                      </div>
                    </div>
                    <!-- End Icon Block -->

                    <!-- Icon Block -->
                    <div class="media text-body font-size-1 mb-2">
                      <div class="min-w-3rem text-center mr-3">
                        <i class="fas fa-globe" />
                      </div>
                      <div class="media-body">
                        {{ resource.language }}
                      </div>
                    </div>
                    <!-- End Icon Block -->

                    <!-- Icon Block -->
                    <div class="media text-body font-size-1 mb-2">
                      <div class="min-w-3rem text-center mr-3">
                        <i class="far fa-credit-card" />
                      </div>
                      <div class="media-body">
                        {{ resource.fee }}
                      </div>
                    </div>
                    <!-- End Icon Block -->

                    <div v-if="resource.website" class="mt-4">
                      <a id="ga-webinar-detail-button" class="btn btn-block btn-primary" :href="resource.website" target="_blank">{{ resource.button_text }}</a>
                    </div>
                  </div>
                </div>

                <a v-if="resource.more_card" id="ga-webinar-post-event-card" class="mt-5 card card-bg-light card-frame text-left h-100 transition-3d-hover w-md-80 w-lg-100 mx-auto" href="javascript:;" data-toggle="modal" data-target="#webinarResourcesModal">
                  <div v-for="item in resource.more_card" :key="item.title" class="card-body">
                    <h3>{{ item.title }}</h3>
                    <p>{{ item.description }}</p>
                    <span class="font-weight-bold">{{ item.button_text }} <i class="fas fa-angle-right fa-sm ml-1" /></span>
                    <div class="position-absolute bottom-0 right-0 w-lg-35 max-w-27rem">
                      <img class="img-fluid" :src="require('@/assets/svg/illustrations/' + item.image_url)">
                    </div>
                    <div class="mt-2 w-50 w-md-25 float-right d-lg-none">
                      <img class="img-fluid" :src="require('@/assets/svg/illustrations/' + item.image_url)">
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      <!-- ========== End Sidebar Content Section ========== -->
      </div>

      <!-- ========== Description Section ========== -->
      <div class="container">
        <div class="row">
          <div class="col-lg-7 col-xl-8 pr-lg-5">

            <!-- Info -->
            <div class="space-top-2">

              <div v-if="resource.organiser" class="mb-8">
                <h3>Organisers</h3>
                <div class="row justify-content-lg-center">
                  <div v-for="item in resource.organiser" :key="item.company" class="col-6 col-md-3 text-center">
                    <img class="mb-1" style="max-width:8rem" :src="require('@/assets/img/events/' + item.image_url)">
                  </div>
                </div>
              </div>

              <div v-if="resource.about" class="mb-8">
                <h3>About this {{ resource.category }}</h3>
                <p v-for="item in resource.about" :key="item">{{ item }}</p>
                <div v-if="resource.about_special" v-html="resource.about_special" />
              </div>

              <div v-if="resource.agenda" class="mb-8">
                <h3>Agenda</h3>
                <ul>
                  <li v-for="item in resource.agenda" :key="item" class="mb-3">
                    <span>{{ item }}</span>
                  </li>
                </ul>
              </div>

              <div v-if="resource.host" class="mb-8">
                <h3>Host</h3>
                <ul>
                  <li v-for="item in resource.host" :key="item.name" class="mb-4">
                    <span class="d-block">{{ item.name }}</span>
                    <span class="d-block text-body font-size-1">{{ item.job_title }}</span>
                    <div class="border-left border-3 pl-4 mt-2">
                      <p>{{ item.bio }}</p>
                    </div>
                  </li>
                </ul>
              </div>

              <div v-if="resource.moderator" class="mb-8">
                <h3>Moderator</h3>
                <ul>
                  <li v-for="item in resource.moderator" :key="item.name" class="mb-4">
                    <span class="d-block">{{ item.name }}</span>
                    <span class="d-block text-body font-size-1">{{ item.job_title }}</span>
                    <div class="border-left border-3 pl-4 mt-2">
                      <p>{{ item.bio }}</p>
                    </div>
                  </li>
                </ul>
              </div>

              <div v-if="resource.speaker" class="mb-8">
                <h3>Speakers</h3>
                <ul>
                  <li v-for="item in resource.speaker" :key="item.name" class="mb-4">
                    <span class="d-block">{{ item.name }}</span>
                    <span class="d-block text-body font-size-1">{{ item.job_title }}</span>
                    <div class="border-left border-3 pl-4 mt-2">
                      <p>{{ item.bio }}</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div v-if="resource.organiser_about" class="mb-8">
              <h3>About the Organisers</h3>
              <ul>
                <div v-if="resource.organiser_about" v-html="resource.organiser_about" />
              </ul>
            </div>

            <div v-if="resource.website" class="d-lg-none space-top-1 text-center w-md-80 mx-auto" data-aos="fade-up">
              <a id="ga-webinar-detail-button" class="btn btn-block btn-primary transition-3d-hover" :href="resource.website" target="_blank">{{ resource.button_text }}</a>
            </div>

            <div class="d-lg-none space-1 w-md-80 mx-auto">
              <a v-if="resource.more_card" id="ga-webinar-post-event-card" class="mt-5 card card-bg-light card-frame text-left h-100 transition-3d-hover" href="javascript:;" data-toggle="modal" data-target="#webinarResourcesModal">
                <div v-for="item in resource.more_card" :key="item.title" class="card-body">
                  <h3>{{ item.title }}</h3>
                  <p>{{ item.description }}</p>
                  <span class="font-weight-bold">{{ item.button_text }} <i class="fas fa-angle-right fa-sm ml-1" /></span>
                  <div class="mt-2 w-50 w-md-25 float-right">
                    <img class="img-fluid" :src="require('@/assets/svg/illustrations/' + item.image_url)">
                  </div>
                </div>
              </a>
            </div>

          </div>
        <!-- End Info -->

        </div>
      </div>
      <!-- ========== End Description Section ========== -->

      <!-- Sticky Block End Point -->
      <div id="stickyBlockEndPoint" />

      <div class="space-1" />

      <div v-if="resource.gallery" class="bg-img-hero" :style="{'background-image': 'url(' + require('@/assets/svg/components/ben-career-hero.svg') + ')'}">
        <div class="container space-2">
          <h3>Gallery</h3>
          <div
            class="js-slick-carousel slick slick-equal-height slick-gutters-2"
            data-hs-slick-carousel-options="{
                &quot;prevArrow&quot;: &quot;<span class=\&quot;fa fa-arrow-left slick-arrow slick-arrow-primary-white slick-arrow-left slick-arrow-centered-y shadow-soft rounded-circle ml-sm-n3 d-none d-lg-block\&quot;></span>&quot;,
                &quot;nextArrow&quot;: &quot;<span class=\&quot;fa fa-arrow-right slick-arrow slick-arrow-primary-white slick-arrow-right slick-arrow-centered-y shadow-soft rounded-circle mr-sm-n3 d-none d-lg-block\&quot;></span>&quot;,
                &quot;infinite&quot;: true,
                &quot;autoplay&quot;: true,
                &quot;autoplaySpeed&quot;: 6000,
                &quot;dots&quot;: true,
                &quot;dotsClass&quot;: &quot;slick-pagination d-flex justify-content-center d-lg-none mt-3&quot;,
                &quot;slidesToShow&quot;: 3,
                &quot;responsive&quot;: [{
                  &quot;breakpoint&quot;: 1200,
                    &quot;settings&quot;: {
                      &quot;slidesToShow&quot;: 3
                    }
                  }, {
                  &quot;breakpoint&quot;: 992,
                    &quot;settings&quot;: {
                      &quot;slidesToShow&quot;: 2
                    }
                  }, {
                  &quot;breakpoint&quot;: 768,
                  &quot;settings&quot;: {
                    &quot;slidesToShow&quot;: 1
                  }
                  }]
              }"
          >
            <div v-for="item in resource.gallery" :key="item.image_url" class="js-slide">
              <img class="img-fluid" :src="require('@/assets/img/events/webinars/gallery/' + item.image_url)">
            <!-- <div class="card bg-img-hero min-h-300rem" :style="{'background-image': 'url(' + require('@/assets/img/events/webinars/banners/'+item.image_url) + ')'}" /> -->
            </div>
          </div>
        </div>
      </div>
      <!-- ========== Popup Modals ========== -->
      <div id="webinarResourcesModal" data-keyboard="false" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="webinarResourcesModalTitle" aria-hidden="true">
        <div class="modal-lg modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 id="webinarResourcesModal" class="modal-title" />
              <button type="button" class="btn btn-xs btn-icon btn-soft-secondary" data-dismiss="modal" aria-label="Close">
                <svg aria-hidden="true" width="10" height="10" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                  <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z" />
                </svg>
              </button>
            </div>
            <div class="modal-body">
              <div v-if="resource.more_card" class="w-md-90 mx-auto mb-6 form-submit">
                <div class="text-center">
                  <img class="img-fluid max-w-23rem mx-auto mb-3" src="@/assets/svg/illustrations/replay-video.svg">
                  <p>This resource is now available on <strong>OpenAPIHub</strong>—our one-stop API Community Platform.<br>Please log in to the platform, or register as an OpenAPIHub member for FREE to watch the resource now!</p>
                  <a id="ga-BW-CC15-01-03" :href="resource.more_card[0].resource_url" target="_blank" class="btn btn-primary transition-3d-hover">Watch on OpenAPIHub</a>
                </div>
              </div>
              <!-- <div class="w-md-90 mx-auto mb-6 form-submit">
                <div v-if="resource.more_card" class="text-center">
                  <h4>{{ resource.more_card[0].popup_title }}</h4>
                  <img class="img-fluid w-50 mx-auto mb-3" src="@/assets/svg/illustrations/replay-video.svg">
                </div>
                <form ref="webinarResourcesModal" />
                <div v-if="isSubmitted" class="modal-submitted-content">
                  <div class="text-center my-4">
                    <span>You may find the webinar resources below:</span>
                  </div>
                  <div v-if="resource.more_card[0].video_url" class="w-lg-90 mx-auto mb-5">
                    <h5>Webinar</h5>
                    <a class="card card-frame p-3" :href="resource.more_card[0].video_url" target="_blank">
                      <div class="d-flex align-items-center">
                        <figure class="max-w-6rem w-100">
                          <img class="img-fluid" src="@/assets/svg/icons/icon-41.svg">
                        </figure>
                        <div class="pl-3 font-weight-bold">Watch Webinar Now</div>
                        <div class="ml-auto"><i class="fas fa-chevron-right" /></div>
                      </div>
                    </a>
                  </div>
                  <div v-if="resource.more_card[0].ppt_url" class="w-lg-90 mx-auto mb-5">
                    <h5>Presentation Slide</h5>
                    <a id="ga-webinar-download-slide-button" class="btn btn-primary btn-block mb-1" :href="`/document/webinar/${resource.more_card[0].ppt_url}`" download>
                      <i class="fas fa-download mr-1" /> Download Presentation Slide
                    </a>
                    <span class="mt-2 font-size-1 d-block">* Unlock the file with password: <span class="text-dark font-weight-bold h5">benovelty</span></span>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <!-- ========== End Popup Modals ========== -->
    </main>
  </div>
</template>

<script>
const $ = require('jquery')
import '../../../assets/js/hs.core.js'
import '../../../assets/js/hs.slick-carousel.js'
import '../../../assets/vendor/slick-carousel/slick/slick.js'
import HSStickyBlock from '@/assets/vendor/hs-sticky-block/src/js/hs-sticky-block'
import Error404 from '@/views/error404/index'
import 'bootstrap'
import { mapGetters } from 'vuex'
export default {
  name: 'EventDetail',
  components: {
    Error404
  },
  data() {
    return {
      resource: {},
      error404occured: false,
      isSubmitted: false
    }
  },
  computed: {
    ...mapGetters([
      'events'
    ])
  },
  created() {
    const title = this.$route.params && this.$route.params.event
    const event_list = this.$store.getters.events.event_list

    // Check whether have the info of the parms
    let i = 0
    let found = false
    while ((i < event_list.length) && (found === false)) {
      if (event_list[i].url.substring(event_list[i].url.indexOf('/') + 1, event_list[i].url.length) === title) {
        this.resource = event_list[i]
        found = true
      } else {
        i = i + 1
      }
    }
    // If not, show error 404
    if (found === false) {
      this.error404occured = true
    }
  },
  mounted() {
    $('.js-sticky-block').each(function() {
      new HSStickyBlock($(this)).init()
    })
    $('.js-slick-carousel').each(function() {
      $.HSCore.components.HSSlickCarousel.init($(this))
    })
    // this.loadScriptWebinarRecordForm('https://js.hsforms.net/forms/v2.js', () => {
    //   const secondScript = document.createElement('script')
    //   secondScript.innerHTML = `
    //     hbspt.forms.create({
    //       portalId: "7091000",
    //       formId: "f5f65673-339f-4bb2-b91f-f4de1ce63e1b"
    //     });
    //   `
    //   this.$refs.webinarRecordForm.appendChild(secondScript)
    // })
    // window.addEventListener('message', event => {
    //   if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {
    //     this.isSubmitted = true
    //   }
    // })
  },
  // methods: {
  //   loadScriptWebinarRecordForm(url, callback) {
  //     var script = document.createElement('script')
  //     script.type = 'text/javascript'
  //     script.src = url
  //     script.onreadystatechange = callback
  //     script.onload = callback
  //     this.$refs.webinarRecordForm.appendChild(script)
  //   }
  // },
  metaInfo() {
    return {
      title: 'beNovelty',
      titleTemplate: this.resource.title + ' | ' + this.resource.category + ' | %s',
      link: [
        // { rel: 'canonical', href: `https://www.benovelty.com/events/${this.resource.url.split('/')[1]}` }
        { rel: 'canonical', href: this.resource.canonical }
      ],
      meta: [
        { name: 'description', content: 'Discover beNovelty\'s state-of-the-art API-First and intelligent Agentic AI Solutions, designed to elevate your enterprise\'s digital transformation.' },
        { property: 'og:site_name', content: 'beNovelty' },
        { property: 'og:title', content: this.resource.title + ' | ' + this.resource.category + ' | beNovelty' },
        { property: 'og:description', content: this.resource.meta_content },
        // { property: 'og:image', content: 'https://www.benovelty.com/assets/img/thumbnails' + this.resource.url.substring(this.resource.url.indexOf('/'), this.resource.url.length) + '.jpg' },
        { property: 'og:image', content: 'https://www.benovelty.com/assets/img/thumbnails/common.jpg' },
        { property: 'og:url', content: 'https://www.benovelty.com/' + this.resource.url },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url("../../../assets/vendor/slick-carousel/slick/slick.css");
</style>
