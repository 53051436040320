<template>
  <main id="content" role="main" class="overflow-hidden">

    <!-- 404 -->
    <div class="container space-3 space-bottom-2 space-bottom-lg-3 overflow-hidden">
      <div class="w-lg-80 mx-lg-auto text-center">
        <img class="img-fluid w-100 w-md-50" src="@/assets/img/images/404.png" alt="Image Description">
        <div class="my-6">
          <h2>Oops, page not found</h2>
          <p>Unfortunately the contents may no longer exist or the address may have been typed incorrectly.</p>
        </div>
        <!-- <a href="/" class="btn btn-primary"> -->
        <router-link class="btn btn-primary" :to="{ path: '/' }">
          Back to Home
        </router-link>
        <!-- </a> -->
      </div>
    </div>
    <!-- End 404 -->

  </main>
</template>

<script>
import 'bootstrap'
export default {
  name: 'Error404',
  components: {
  },
  data() {
    return {
    }
  },
  computed: {
  },
  mounted() {
  }
  // metaInfo() {
  //   return {
  //     title: 'beNovelty',
  //     titleTemplate: '%s',
  //     meta: [
  //       { name: 'description', content: 'An award-winning one-stop API platform services connecting digital businesses to embrace Open API Economy.' }
  //     ]
  //   }
  // }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url("../../assets/vendor/slick-carousel/slick/slick.css");
</style>
